<template>
    <div class="s-landscape-bg">
        <div class="s-main-container s-ptb-60">
            <div :class="`${isMobile?'': 's-center-flex s-wrap s-ht-500'}`">
                <div class="s-column-2" >
                    <h1 v-if="!isMobile" class="s-text-white s-mt-auto">{{$t('login.drmTitle')}}</h1>
                    <h3 v-else class="s-text-white s-mt-auto s-text-center">{{$t('login.drmTitle')}}</h3>
                    <div v-if="!isMobile" class="s-pb-10">
                        <router-link :to="$translate({name: 'Home Public'})" class="s-text-none">
                            <Button color="secondary" class="s-center-flex">
                                <div class="s-square-20">
                                    <img :src="require('@/assets/icons/white/icon-left.svg')"/>
                                </div>
                                {{$t('login.home')}}
                            </Button>
                        </router-link>
                    </div>
                </div>
                <div :class="isMobile? 's-fullWidth':'s-column-2'" >
                    <div :class="isMobile? '':'s-pl-50'">
                        <component :is='currentComponent'></component>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { loadAuth } from '@/router/lazyLoad'
import { mapGetters } from 'vuex'
import Button from '@/components/helper/Button'
export default {
    components:{
        'login': loadAuth('login'),
        'create-account': loadAuth('createAccount'),
        'forgot-password': loadAuth('changePassword'),
        'change-password': loadAuth('changePasswordRedirect'),
        Button
    },
    computed:{
        currentComponent(){
            return this.$route.params.authType || 'login'
        },
        ...mapGetters({
            isMobile: 'getIsMobile'
        })
    }
}
</script>